import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

export default function Support() {
    return (
        <Layout>
            <Seo
                title="Support"
                description="Fill out the form to submit a support request."
            />
            <div className="inttop"></div>
            <div className="app-title support-title">
                <div className="container">
                    <div className="titlefull">
                        <div className="titlefull__txt">
                            <h1>Support</h1>
                            <p>
                                If you require support on the Digiclinic
                                platform, please fill in the details below.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content featurebg">
                <div className="container supportwrap">
                    {/* <div className={errorClass} style={errorStyle}>
                        <span>{errorMsg}</span>
                    </div> */}
                    <div className="support">
                        <div className="support__form">
                            <div className="formitem">
                                <label className="fi__label">
                                    <input
                                        type="text"
                                        name="firstname"
                                        placeholder=" "
                                    />
                                    <span className="fi__span">
                                        Name <small>required</small>
                                    </span>
                                </label>
                            </div>
                            <div className="formitem">
                                <label className="fi__label">
                                    <input
                                        type="text"
                                        name="clinic"
                                        placeholder=" "
                                    />
                                    <span className="fi__span">
                                        Clinic <small>required</small>
                                    </span>
                                </label>
                            </div>
                            <div className="formitem">
                                <label className="fi__label">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder=" "
                                    />
                                    <span className="fi__span">
                                        Email <small>required</small>
                                    </span>
                                </label>
                            </div>
                            <div className="formitem">
                                <label className="fi__label">
                                    <input
                                        type="text"
                                        name="phone"
                                        placeholder=" "
                                    />
                                    <span className="fi__span">Phone No.</span>
                                </label>
                            </div>
                            <div className="formitem fw">
                                <label className="fi__label">
                                    <textarea
                                        type="textarea"
                                        name="message"
                                        placeholder=" "
                                    />
                                    <span className="fi__span">
                                        Details <small>required</small>
                                    </span>
                                </label>
                            </div>
                            <div className="formitem fw">
                                <button className="btn">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
